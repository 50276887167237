<template>
  <div id="login" :style="{backgroundImage: 'url('+ loginBg +')'}">
    <div class="logo">
      <!-- <img src="../assets/logo_jjy.png" /> -->
    </div>

    <transition name="el-zoom-in-center">
      <div class="login" v-show="tab=='login'">
        <div class="title"><i class="el-icon-s-custom"></i>{{loginTitle}}</div>
        <div class="content">
          <div class="mode1">
            <div class="mode-txt">扫一扫登录</div>
            <span>请使用<font style="color: #44A4FA; font-weight: bold;"> 手机微信 </font>扫码登录</span>
            <div style="border: 1px solid #EEEEEE; width: 200px; height: 200px;" v-loading="qrLoading">
              <vue-qr ref="Qrcode" :text="dataObj.text" :callback="test" qid="testQrId" v-show="dataObj.text">
                <!-- :logoSrc="dataObj.logo" 在二维码中间加图标 -->
              </vue-qr>
            </div>
            <el-button type="primary" plain size="small" @click="refreshQRCode">刷新二维码</el-button>
          </div>
          <div class="mode2">
            <div class="mode-txt">
              <div class="c-title">登录</div>
              <div v-if="lockedTime !=0 " class="c-tips">密码多次错误此账号已被锁定 {{lockedTime}}后重试</div>
            </div>
            <el-input placeholder="请输入账号" prefix-icon="el-icon-user" clearable v-model="username" autofocus @keyup.enter.native="loginReady">
            </el-input>
            <br />
            <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" show-password v-model="password" @keyup.enter.native="loginReady">
            </el-input>
            <br />
            <el-button type="primary" :loading="isLoading" @click="loginReady">
              {{isLoading?'正在登录，请稍后':'立即登录'}}
            </el-button>
            <!-- greetings -->
            <span style="text-align: center;">{{greetings}}</span>
            <!-- <div class="register">
							<el-link type="primary" @click="tab='register'">注册账号</el-link>
						</div> -->
          </div>
        </div>
      </div>
    </transition>
    <Register @back="back" v-show="tab=='register'"></Register>
  </div>

</template>

<script>
import VueQr from 'vue-qr';
import Register from '../components/register.vue';
import { login } from '@/api/user.js'

import {
  baseUrl
} from '../common/request/env'

export default {
  components: {
    VueQr,
    Register,
  },
  data() {
    return {
      loginBg: '', //背景图
      mainTitle: '',
      greetings: '',
      loginTitle: '',

      username: '',
      password: '',
      isLoading: false,
      tab: 'login', //login登录，register注册，forget忘记密码

      dataObj: {
        text: '',
        logo: require('../assets/logo_jjy.png')
      },
      exportLink: '',
      downloadFilename: '',

      qrLoading: false, //刷新二维码等待
      lockedTime: 0,//账号锁定剩余时间
      lockTime: '',
    }
  },
  watch: {
    '$store.state.settingStore.logo': {
      handler(val) {
        //登录页标题
        val.loginTitle ? this.loginTitle = val.loginTitle : ''
        //背景图
        val.loginBKPicture != undefined ? this.loginBg = val.url + val.loginBKPicture : val.url + '/logo/default/bg.jpg'
        //问候语
        val.greetings != undefined ? this.greetings = val.greetings : ''
      }
    }
  },
  methods: {
    setLogo() {
      let logo = this.$store.getters['settingStore/getLogo']    // 获取定制内容
      //登录页标题
      logo.loginTitle ? this.loginTitle = logo.loginTitle : ''
      //背景图
      logo.loginBKPicture != undefined ? this.loginBg = logo.url + logo.loginBKPicture : logo.url + '/logo/default/bg.jpg'
      //问候语
      logo.greetings != undefined ? this.greetings = logo.greetings : ''

    },
    loginReady() {
      if (this.username == "") {
        this.common.cToast(this, '请输入账号');
        return;
      }
      if (this.password == "") {
        this.common.cToast(this, '请输入密码');
        return;
      }
      // let json = {
      // 	"cmd": "userLogin",
      // 	"user": this.username,
      // 	"password": this.password
      // }
      // this.ws.send(json);
      login({ "username": this.username, "password": this.password })
      this.isLoading = true;
    },
    refreshQRCode() {
      let ws = this.ws;
      ws.send({
        "cmd": "createLoginToken"
      });
      // this.qrLoading = true;
    },
    test(dataUrl, id) {
      // console.log(dataUrl)
      // console.log(id)
    },
    // 注册界面返回登录
    back() {
      this.tab = 'login';
    },
    getTime(res) {
      let time = res
      if (this.lockTime == '') {
        this.lockTime = setInterval(() => {
          time--
          if (time === 0) {
            this.lockedTime = 0
            clearInterval(this.lockTime)
            this.lockTime = ''
          } else {
            if (time > 60) {//如果秒数大于60，将秒数转换成整数
              //获取秒数，秒数取余，得到整数秒数
              this.lockedTime = parseInt(time / 60) + '分钟';
            } else {
              this.lockedTime = time + '秒';
            }
          }

        }, 1000)
      }
    }
  },
  beforeCreate() {
    let user = this.common.cGetUser();
    if (user.username != "") {
      location.reload();
    }
  },
  created() {
    // 刷新二维码
    this.refreshQRCode();
    this.setLogo()

    // 退出登录
    let json = {
      "cmd": "userLogout"
    }
    this.ws.send(json);

    // 设置空user
    this.common.cSetUser('', '', '', 0, 0, '');
  },
  mounted() {
    // 获取定制背景图
    let url = "";
    //是在node.js环境下运行,连接IotPlatform.exe，这里主要方便测试显示出图片
    if (location.port == "8080") {
      url = `${location.protocol}//${document.domain}`;
    }
    // this.loginBg = url + "/logo/default/bg.jpg"; //默认背景图

    {
      console.log("document.domain=" + document.domain);
      console.log("location.port=" + location.port);
    }

    let _this = this;
    // _this.req.getLogo().then(function (res) {
    //   console.log("logo.res=", res);
    //   _this.common.cSetLogo(res);
    //   if (res.loginBKPicture) {
    //     _this.loginBg = url + res.loginBKPicture;
    //   }
    //   if (res.greetings) {
    //     _this.greetings = res.greetings;
    //   }
    //   if (res.mainTitle) {
    //     _this.mainTitle = res.mainTitle;
    //   }

    // })

    // 登录result
    this.ws.addCallback("userLoginAck", (res) => {
      this.isLoading = false;
      if (!this.common.cGetResult(res)) {
        let toastMsg = "";
        switch (res.errorName) {
          case "EUserNotExists":
            toastMsg = "用户名不存在";
            break;
          case "EPasswordWrong":
            toastMsg = "密码错误";
            break;
          case "EAlreadyLogin":
            toastMsg = "账号已登录";
            break;
          case "EUserDisabled":
            toastMsg = "该用户被停用";
            break;
          case "EUserLocked":
            toastMsg = "该用户被锁定";
            this.getTime(res.seconds)
            break;
          default:
            toastMsg = "登录失败";
            break;
        }
        this.common.cToast(this, toastMsg);
        return;
      }
      let loginMode = "pwd";
      let nickname = res.user ? res.user : this.username;
      let wxToken = "";
      if (res.reconnectToken) {
        loginMode = "code";
        wxToken = res.reconnectToken;
      }
      this.common.cSetUser(loginMode, this.username, this.password, res.isAdmin, res.isManager, nickname, wxToken);
      setTimeout(() => {
        this.$router.push({
          path: '/main'
        })
      }, 1)
    });

    this.ws.addCallback('createLoginTokenAck', (res) => {
      // console.log("createLoginTokenAck is ", res);
      // this.qrLoading = false;
      this.dataObj.text = "https://" + baseUrl + "/wx?login=1&token=" + res.token;
    })

    this.ws.addCallback("connected", (res) => {
      // console.log("login recv connected");

      {
        let ws = this.ws;
        ws.send({
          "cmd": "createLoginToken"
        });

      }
    });

    if (this.ws.isConnected()) {
      // console.log("ws is already connected");

      let ws = this.ws;
      ws.send({
        "cmd": "createLoginToken"
      });

    } else {
      // console.log("ws is NOT connected");
    }

    //断开连接
    this.ws.addCallback('unConnected', (res) => {
      this.dataObj.text = "";
    })
  },
}
</script>

<style lang="scss">
#login {
  height: 100vh;
  // background-image: url(../assets/bg.jpg);
  background-size: cover;

  .logo {
    padding: 2% 5%;
  }

  .login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 700px;
    height: 480px;
    box-shadow: 0 2px 10px #cccccc;
    border-radius: 8px;
    background-color: rgba($color: $color_white, $alpha: 0.9);

    .title {
      padding: 3% 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      background-color: rgba($color: #44a4fa, $alpha: 0.1);
      color: #44a4fa;
      text-shadow: 1px 1px 0 $color_white;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 26px;
        margin-right: 6px;
      }
    }

    .content {
      margin: 5% 0;
      display: flex;
      flex-direction: row;

      .mode1 {
        flex: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5%;

        .el-button {
          width: 120px;
          margin-top: 10px;
        }
      }

      .mode2 {
        width: 300px;
        display: flex;
        flex-direction: column;
        margin: 0 5%;

        .el-button {
          width: 100%;
          // margin: 40px 0 20px 0;
          margin: 20px 0;
        }

        .register {
          text-align: center;
        }
        .c-title {
          float: left;
        }
        .c-tips {
          font-size: 12px;
          float: right;
          text-align: center;
          line-height: 24px;
          color: #fb2727;
        }
      }

      .mode-txt {
        margin: 20px 0;
        font-size: 18px;
        font-weight: bold;
      }
    }

    // 重写element样式
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
